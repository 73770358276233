import React, { useEffect } from 'react'
import { useMedia } from 'react-use'
import classNames from 'classnames'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, EmailShareButton } from 'react-share'
import { useParams } from 'react-router-dom'
import { FaFacebookF, FaRegEnvelope, FaTelegramPlane, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import propTypes from 'prop-types'

import I18n from 'lang'
import Title from 'layouts/Title'
import Buttonless from 'components/Buttonless'
import Menu from 'components/Menu'
import HistoricalGames from 'layouts/HistoricalGames'
import ClickOutside from 'components/ClickOutside'
import { useClickOutside } from 'hooks/useGame'
import useSiteStore from 'store/site'
import useGameStore from 'store'
import gamesApi, { getUserId } from 'api'

import { ReactComponent as Trophy21 } from 'assets/trophy-fill-21.svg'
import { ReactComponent as Trophy } from 'assets/trophy-fill.svg'
import { ReactComponent as Gear } from 'assets/gear-fill.svg'
import { ReactComponent as X } from 'assets/x-lg.svg'
import { ReactComponent as SaveFill } from 'assets/save-fill.svg'
import { ReactComponent as ArrowBarRight } from 'assets/arrow-bar-right.svg'
import { ReactComponent as Star } from 'assets/star.svg'
import { updateMomentLocale } from 'helpers/locale'

export const STATUS_INIT = 'init'
export const STATUS_PAUSE = 'pause'
export const STATUS_PLAYING = 'playing'
export const STATUS_FINISHED = 'finished'
export const STATUS_INCOMPLETE = 'incomplete'
export const STATUS_RESTARTED = 'restarted'
export const STATUS_RESOLVED = 'resolved'
export const STATUS_RESTART = 'restart'
export const STATUS_RESOLVE = 'resolve'
export const STATUS_PAST = 5
export const STATUS_EXIT = 'exit'
export const STATUS_TO_SAVE_AND_EXIT = 'toSaveAndExit'
export const STATUS_SAVE_AND_EXIT = 'saveAndExit'
export const STATUS_ERROR_SAVING = 'errorSaving'
export const STATUS_ERROR_RESTARTING = 'errorRestarting'
export const STATUS_ERROR_RESOLVING = 'errorResolving'
export const STATUS_ERROR_FINISHING = 'errorFinishing'
export const STATUS_ERROR_CHECKING = 'errorChecking'
export const STATUS_TO_RESOLVE = 'toResolve'

export const Overflow = (props) => {
  if (!props.active) {
    return null
  }

  return (
    <div
      className={classNames(
        props.className,
        'em-w-full em-h-full em-left-0 em-top-0 em-z-40',
        'em-bg-white em-bg-opacity-95',
        {
          'em-fixed': props.fixed,
          'em-absolute': !props.fixed,
        },
      )}
    >
      <div
        className={classNames('em-top-10 em-flex em-flex-col em-items-center em-space-y-10 sm:em-py-20', {
          'em-sticky': props.sticky,
        })}
      >
        {props.children}
      </div>
    </div>
  )
}

export const Alert = (props) => {
  return (
    <div
      className={classNames(
        props.className,
        'em-bg-gray-100 em-shadow-md sm:em-rounded-xl',
        'em-px-10 sm:em-px-20 em-py-16 sm:em-py-20',
        'em-flex em-flex-col em-space-y-5 sm:em-max-w-lg',
        'em-relative em-w-full em-h-full sm:em-h-auto',
      )}
    >
      {props.onClose && (
        <Buttonless
          onClick={props.onClose}
          className='em-absolute em-flex em-items-center em-space-x-3 em-right-3 em-top-3'
        >
          <X width={14} />
          <span>
            <I18n t='close' />
          </span>
        </Buttonless>
      )}
      {props.children}
    </div>
  )
}

const ModalEndGameStatistics = ({
  titleBgColor = '',
  shareUrl: _shareUrl = '',
  endGame = undefined,

  isOpen = false,
  onClose = () => {},
  shareEndContent = false,
  statistics = [],
  share = undefined,

  icon,
  title,
  content,
  onDate,
}) => {
  const siteStore = useSiteStore()
  const [_isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  let shareUrl = _shareUrl
  if (!!endGame && shareUrl) {
    shareUrl = shareUrl.split('/share').join('/shareend')
  }

  if (shareUrl) {
    const t = shareUrl.includes('?') ? '&' : '?'
    shareUrl += `${t}shareuuid=${uuidv4()}&siteid=${siteStore.site?.id}`
  }

  return (
    <Overflow
      active={_isOpen}
      className='em-z-[100]'
    >
      <Alert
        onClose={onClose}
        className='em-sticky em-top-0 em-w-full em-h-full em-max-w-lg em-rounded-none sm:em-h-auto'
      >
        <div className='em-w-full em-text-white em-bg-disabled em-rounded-3xl'>
          {shareEndContent || (
            <>
              <div className='em-flex em-justify-end em-p-4'>
                <img
                  src={siteStore.site?.logo}
                  alt={siteStore.site?.name}
                  className='em-h-4 !em-w-auto'
                />
              </div>
              <div
                className='!em-font-semibold em-flex em-items-center em-justify-between em-px-3 em-py-2 em-text-3xl em-mb-5'
                style={{ backgroundColor: titleBgColor }}
              >
                <div className='em-max-h-[40px] em-max-w-[40px] em-p-1 em-rounded-full em-bg-white'>
                  {icon && (
                    <img
                      src={icon}
                      alt='share icon'
                      className='em-w-full em-h-full'
                    />
                  )}
                </div>
                {title}
              </div>
              <div className='em-p-5'>{content}</div>
              <div className='em-p-5 em-mb-4'>
                {statistics?.map((statistic, index) => (
                  <>
                    <div
                      key={`statistic-${index}`}
                      className='em-flex em-items-center em-justify-between em-py-2 em-border-t last:em-border-b em-border-disabled-light'
                    >
                      {statistic.i18nKey && <I18n t={statistic.i18nKey} />}
                      {statistic?.label}
                      <div className='em-text-xl'>{statistic.value}</div>
                    </div>
                    {statistic?.children && (
                      <div className='em-flex em-justify-between em-pb-2 em-space-x-2'>
                        {statistic.children.map((child, indexChild) => (
                          <div
                            key={`child_statistics_${indexChild}`}
                            className='em-flex em-flex-col em-justify-center'
                          >
                            <div className='em-text-xs em-text-center'>{child.label}</div>
                            <div className='em-text-xl em-text-center sm:em-text-2xl'>{child.value}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ))}
                {endGame && (
                  <div className='em-flex em-items-center em-justify-center em-py-2 em-border-t em-text-disabled-light em-border-disabled-light'>
                    <I18n
                      t='game.messages.complete.onDate'
                      args={onDate}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {share && (
          <>
            <div className='em-text-center em-text-xl !em-font-semibold'>
              <I18n t='game.messages.complete.share' />
            </div>
            <div className='em-flex em-items-center em-justify-center em-space-x-4'>
              <FacebookShareButton
                className='em-overflow-hidden em-rounded-full'
                url={shareUrl}
              >
                <FaFacebookF
                  size={45}
                  className='em-p-3 em-transition-all em-bg-disabled hover:em-bg-disabled-dark em-text-disabled-lightest'
                />
              </FacebookShareButton>
              <TwitterShareButton
                className='em-overflow-hidden em-rounded-full'
                url={shareUrl}
              >
                <FaTwitter
                  size={45}
                  className='em-p-3 em-transition-all em-bg-disabled hover:em-bg-disabled-dark em-text-disabled-lightest'
                />
              </TwitterShareButton>
              <a
                href={`whatsapp://send/?text=${encodeURIComponent(shareUrl)}`}
                target='_blank'
                className={classNames(
                  'em-w-[52px] em-h-[52px] em-bg-[#555555]',
                  'em-rounded-full flex em-justify-center em-items-center em-overflow-hidden',
                  'em-flex em-items-center em-justify-center em-text-disabled-lightest',
                )}
              >
                <svg
                  stroke='currentColor'
                  fill='currentColor'
                  strokeWidth='0'
                  viewBox='0 0 448 512'
                  className={classNames(
                    'hover:em-bg-disabled-dark em-p-3 em-rounded-full em-transition-all',
                    'em-bg-disabled em-text-disabled-lightest em-border-[0.1px] em-border-disabled-light',
                  )}
                  height='48'
                  width='48'
                >
                  <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                </svg>
              </a>
              <TelegramShareButton
                className='em-overflow-hidden em-rounded-full'
                url={shareUrl}
              >
                <FaTelegramPlane
                  size={45}
                  className='em-p-3 em-transition-all em-bg-disabled hover:em-bg-disabled-dark em-text-disabled-lightest'
                />
              </TelegramShareButton>
              <EmailShareButton
                className='em-overflow-hidden em-rounded-full'
                url={shareUrl}
              >
                <FaRegEnvelope
                  size={45}
                  className='em-p-3 em-transition-all em-bg-disabled hover:em-bg-disabled-dark em-text-disabled-lightest'
                  body={shareUrl}
                />
              </EmailShareButton>
            </div>
          </>
        )}
        {endGame && !shareEndContent && (
          <div className='em-text-lg em-text-center'>
            <I18n t='game.messages.complete.shortDescription' />
          </div>
        )}
      </Alert>
    </Overflow>
  )
}

/**
 * @param {Object} props
 * @param {string} props.status Current status of the game
 * @param {function} props.onClickOutside Function to call when clicking outside the game
 * @param {function} props.saveGame Function to save the game
 * @param {string} props.title
 * @param {HistoricalGames} props.historicalGames
 * @param {array} props.statistics Array of statistics from API
 * @param {string} props.publicationDate Date of the game from API
 * @param {array} props.mobileMenu Array of menu items. Use when it is needed to show an extra menu on mobile
 * @param {array} props.helpMenu Array of menu items
 * @param {array} props.leftMenu Array of menu items
 * @param {array} props.middleMenu Array of menu items
 * @param {string} props.by Author
 *
 * @returns {React.Component}
 */
export default function Game(props) {
  const { locale = 'es' } = useParams()
  const [showMenu, setShowMenu] = React.useState(false)
  const isMobile = useMedia('(max-width: 625px)')
  const [shareStats, setShareStats] = React.useState(null)
  const [shareStatsEnd, setShareStatsEnd] = React.useState(null)

  const location = useLocation()
  const shareuserid = new URLSearchParams(location.search).get('shareuserid')
  const shareend = new URLSearchParams(location.search).get('shareend')

  const { toasts, setToast } = useGameStore((store) => ({
    toasts: store.toasts,
    setToast: store.setToast,
  }))
  const { autosave } = useSiteStore((store) => ({ autosave: store.autosave }))

  const {
    status,
    saveGame,
    onClickOutside,
    exitGame,
    title,
    historicalGames,
    statistics,
    publicationDate,
    mobileMenu = [],
    helpMenu = [],
    leftMenu = [],
    middleMenu = [],
    by = false,
    endGameModal = {},
    rightMenuStart = [],
    rightMenuEnd = [],
    statsUrl = '',
  } = props

  React.useEffect(() => {
    updateMomentLocale(locale)
  }, [locale])

  React.useEffect(() => {
    const fetchStatsForUser = async () => {
      if (shareend) {
        setShareStatsEnd(shareend)
      } else {
        const response = await gamesApi.post(statsUrl, {
          userid: shareuserid,
        })
        setShareStats(response.data)
      }
    }
    if (shareuserid) {
      fetchStatsForUser()
    }
  }, [shareuserid, shareend]) // eslint-disable-line react-hooks/exhaustive-deps

  const refGame = React.useRef(null)
  useClickOutside(
    refGame,
    (newUrl) => onClickOutside(newUrl),
    status !== STATUS_INCOMPLETE && status !== STATUS_FINISHED && status !== STATUS_INIT,
  )

  const statusRef = React.useRef(null)
  const saveGameRef = React.useRef(null)
  const autosaveRef = React.useRef(null)

  React.useEffect(() => {
    statusRef.current = status
    saveGameRef.current = saveGame
    autosaveRef.current = autosave
  }, [status, saveGame, autosave])

  // React.useEffect(() => {
  //   const el = refGame.current

  //   if (!autosaveRef.current) {
  //     saveGameRef.current = () => {}
  //     el.removeEventListener('mouseout', () => {}, false)
  //     return null
  //   }

  //   el.addEventListener('mouseout', () => {
  //     saveGameRef.current && saveGameRef.current()
  //     return null
  //   })

  //   return () => {
  //     el.removeEventListener('mouseout', () => {}, false)
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const userId = getUserId()

  const rightMenu = [
    ...rightMenuStart,
    {
      href: '',
      disabled: !userId,
      onClick: () => setShowMenu(showMenu === 'stats' ? false : 'stats'),
      className: 'em-hidden md:em-flex',
      icon: Trophy,
      label: <I18n t='game.buttons.statistics' />,
    },
    {
      href: '/',
      disabled: status === STATUS_FINISHED,
      onClick: (e) => {
        saveGame()
        setToast({
          id: uuidv4(),
          message: I18n.getTranslation({ pathname: '/' + locale }, 'game.messages.saved.message'),
        })
        e.preventDefault()
      },
      className: 'ignore-click-outside',
      icon: SaveFill,
      label: <I18n t='game.actions.save' />,
    },
    {
      onClick: exitGame,
      icon: ArrowBarRight,
      label: <I18n t='game.actions.exit' />,
    },
    ...rightMenuEnd,
  ]

  const now = moment()

  return (
    <>
      <div
        className='em-relative em-z-40'
        style={{ minHeight: '400px' }}
      >
        <div>
          <Title
            title={title}
            by={by}
            date={publicationDate}
          />

          <HistoricalGames {...historicalGames} />

          <div
            className='em-relative em-pointer-events-auto'
            ref={refGame}
          >
            {toasts?.['bottom'] && (
              <div
                {...props}
                className='
                  em-fixed em-bottom-4 em-left-0 em-right-0 em-z-[100] em-justify-center em-items-center
                  em-pointer-events-none
                  em-flex em-flex-col em-space-y-2
                '
              >
                {toasts['bottom'].map((toast, index) => (
                  <div
                    key={`toast_${toast.id}`}
                    className={classNames(
                      'em-bg-yellow-secondary em-text-black em-rounded-lg em-px-10 em-py-5',
                      toast.className || '',
                    )}
                  >
                    {toast.message}
                  </div>
                ))}
              </div>
            )}
            {toasts?.['top'] && (
              <div
                {...props}
                className='
                  em-absolute em-top-4 em-left-0 em-right-0 em-z-[100] em-justify-center em-items-center
                  em-pointer-events-none
                  em-flex em-flex-col em-space-y-2
                '
              >
                {toasts['top'].map((toast, index) => (
                  <div
                    key={`toast_${toast.id}`}
                    className={classNames(
                      'em-bg-yellow-secondary em-text-black em-rounded-lg em-px-10 em-py-5',
                      toast.className || '',
                    )}
                  >
                    {toast.message}
                  </div>
                ))}
              </div>
            )}
            {!isMobile && (
              <nav className='em-flex em-items-center em-border-t em-border-b em-border-disabled-lighter !em-m-0 !em-w-auto'>
                <Menu
                  parentClassName='em-flex-grow em-flex-1'
                  itemClassName='em-uppercase !em-font-semibold'
                  items={leftMenu}
                />

                <div className='em-flex em-justify-center em-flex-1 em-flex-grow'>{middleMenu}</div>

                <Menu
                  parentClassName='em-flex-grow em-flex-1 em-flex em-justify-end'
                  itemClassName='em-text-sm hover:em-text-primary'
                  items={rightMenu}
                />
              </nav>
            )}
            {isMobile && middleMenu && (
              <nav className='em-flex em-justify-between em-items-center em-border-t em-border-b em-border-disabled-lighter !em-m-0 !em-w-auto'>
                {middleMenu}

                <Menu
                  className='em-mr-4'
                  noPadding
                  items={[
                    ...mobileMenu.map((item) => ({
                      ...item,
                      active: showMenu === item.id || item.active,
                      onClick: () => {
                        setShowMenu(showMenu === item.id ? false : item.id)
                        item.onOpen && item.onOpen()
                        item.onClick && item.onClick()
                      },
                    })),
                    {
                      icon: Trophy21,
                      onClick: () => setShowMenu(showMenu === 'stats' ? false : 'stats'),
                      active: showMenu === 'stats',
                    },
                    {
                      icon: Gear,
                      active: showMenu === 'options',
                      optionPosition: 'right',
                      options: [...helpMenu, ...rightMenu],
                    },
                  ]}
                />
              </nav>
            )}
            {isMobile &&
              mobileMenu.length > 0 &&
              mobileMenu.map((menu) => {
                if (menu.id !== showMenu && !menu.active) {
                  return null
                }

                return (
                  <ClickOutside
                    onClickOutside={() => {
                      if (!menu.alwaysVisible) {
                        showMenu === menu.id && setShowMenu(false)
                        menu.onClose()
                      }
                    }}
                  >
                    {menu.content}
                  </ClickOutside>
                )
              })}

            <ModalEndGameStatistics
              {...endGameModal}
              endGame
              share
            />
            <ModalEndGameStatistics
              shareEndContent={
                shareStatsEnd ? (
                  <img
                    src={shareStatsEnd}
                    alt='Share End Game'
                  />
                ) : null
              }
              isOpen={shareStatsEnd !== null}
              onClose={() => setShareStatsEnd(null)}
              endGame
            />

            <ModalEndGameStatistics
              {...endGameModal}
              isOpen={showMenu === 'stats'}
              onClose={() => setShowMenu(false)}
              statistics={
                statistics[0]?.reduce((red, statistic, index) => {
                  const value = (
                    <React.Fragment key={`statistics_${index}`}>
                      <span>
                        {statistic?.type === 'date' ? moment(statistic?.value * 1000).format('mm:ss') : statistic.value}
                      </span>{' '}
                      {statistic?.percentage > 0 && <span>({parseInt(statistic.percentage * 100) / 100} %)</span>}
                    </React.Fragment>
                  )

                  if (statistic.valueLabel) {
                    return red
                  }
                  return [
                    ...red,
                    {
                      label: statistic.label,
                      value,
                      children: statistic.children,
                    },
                  ]
                }, []) || []
              }
              content={
                statistics[0]?.map((statistic) => {
                  let value = null
                  if (statistic.valueLabel) {
                    value = (
                      <div className='em-w-full em-mt-1 em-text-center'>
                        <div className='em-uppercase'>
                          <I18n t='game.buttons.statistics' />
                        </div>
                        <div className='em-mb-3 em-text-sm em-capitalize'>{now.format('dddd DD.MM.YYYY - HH:mm')}</div>
                        <div className='em-flex em-justify-around em-mb-4 em-space-x-2'>
                          {[...Array(5).keys()].map((i) => {
                            if (i < statistic.value) {
                              return (
                                <Star
                                  width={50}
                                  height={50}
                                  key={`star_${i}`}
                                  style={{
                                    color: '#F0EB4F',
                                  }}
                                />
                              )
                            }
                            return (
                              <Star
                                width={50}
                                height={50}
                                key={`star_${i}`}
                              />
                            )
                          })}
                        </div>
                        <div
                          style={{ color: '#F0EB4F' }}
                          className='em-mt-1 em-uppercase'
                        >
                          {statistic.label}: {statistic.valueLabel}
                        </div>
                      </div>
                    )
                  }
                  return value
                }) || null
              }
              titleBgColor={endGameModal?.titleBgColor}
              icon={endGameModal.icon}
              title={endGameModal.title}
              share
            />

            <ModalEndGameStatistics
              {...endGameModal}
              isOpen={shareStats !== null}
              onClose={() => setShareStats(null)}
              statistics={
                shareStats?.[0]?.reduce((red, statistic) => {
                  const value = (
                    <>
                      <span>
                        {statistic?.type === 'date' ? moment(statistic?.value * 1000).format('mm:ss') : statistic.value}
                      </span>{' '}
                      {statistic?.percentage > 0 && <span>({parseInt(statistic.percentage * 100) / 100} %)</span>}
                    </>
                  )

                  if (statistic.valueLabel) {
                    return red
                  }
                  return [
                    ...red,
                    {
                      label: statistic.label,
                      value,
                    },
                  ]
                }, []) || []
              }
              content={
                shareStats?.[0]?.map((statistic) => {
                  let value = null
                  if (statistic.valueLabel) {
                    value = (
                      <div className='em-w-full em-mt-1 em-text-center'>
                        <div className='em-uppercase'>
                          <I18n t='game.buttons.statistics' />
                        </div>
                        <div className='em-mb-3 em-text-sm em-capitalize'>{now.format('dddd DD.MM.YYYY - HH:mm')}</div>
                        <div className='em-flex em-justify-around em-mb-4 em-space-x-2'>
                          {[...Array(5).keys()].map((i) => {
                            if (i < statistic.value) {
                              return (
                                <Star
                                  width={50}
                                  height={50}
                                  key={`star_${i}`}
                                  style={{
                                    color: '#F0EB4F',
                                  }}
                                />
                              )
                            }
                            return (
                              <Star
                                width={50}
                                height={50}
                                key={`star_${i}`}
                              />
                            )
                          })}
                        </div>
                        <div
                          style={{ color: '#F0EB4F' }}
                          className='em-mt-1 em-uppercase'
                        >
                          {statistic.label}: {statistic.valueLabel}
                        </div>
                      </div>
                    )
                  }
                  return value
                }) || null
              }
              titleBgColor={endGameModal.titleBgColor}
              icon={endGameModal.icon}
              title={endGameModal.title}
            />

            {props.children}
          </div>
        </div>
      </div>
    </>
  )
}

Game.propTypes = {
  status: propTypes.string,
  saveGame: propTypes.func,
  title: propTypes.string,
  historicalGames: propTypes.object,
  statistics: propTypes.array,
  publicationDate: propTypes.string,
  mobileMenu: propTypes.array,
  helpMenu: propTypes.array,
  leftMenu: propTypes.array,
  middleMenu: propTypes.object,
  by: propTypes.string,
  endGameModal: propTypes.object,
  rightMenuStart: propTypes.array,
  rightMenuEnd: propTypes.array,
  statsUrl: propTypes.string,
  children: propTypes.node,
}
